<template>
    <div class="tradingData">
        <dataAnalysisTemplate :echoData="echoData" :showData="crowdPortrait" @save="save" :viewData="viewData"></dataAnalysisTemplate>
    </div>
</template>

<script>
    import dataAnalysisTemplate from "./dataAnalysisTemplate";
    import {getDataAnalysisInfo} from "../../../../utils/apis";
    export default {
        name: "tradingData",
        components: {
            dataAnalysisTemplate
        },
        data() {
            return {
                viewData: {},
                crowdPortrait: 'tradingData',
                echoData: {}
            }
        },
        methods: {
            save(val) {
                // val.name = 'tradingData'
                // this.$emit('save', val)
                // sessionStorage.setItem('tradingData', JSON.stringify(val))
                this.$emit('save', 5,val)
            },
            async getDataAnalysisInfoFn() {
                let res = await getDataAnalysisInfo({
                    type: 5
                })
                this.echoData = res.data
            }
        },
        mounted() {
            this.getDataAnalysisInfoFn()
        }
    }
</script>

<style scoped>
    .tradingData {
        width: 100%;
        height: 100%;
    }
</style>