<template>
    <div class="customerInfoLayout">
        <div class="customerInfoLayout_left">
            <div class="dataBtn" @click="dataAggregationClick">数据汇总</div>
            <el-menu
                    :default-openeds="openeds"
                    :default-active="defaultActive"
                    text-color="#6A686F"
                    active-text-color="#F23B2F"
                    class="el-menu-vertical-demo"
                    @select="menuItemSelect">
                <template v-for="(item, index) in menuArr">
                    <el-submenu :index="item.path + ''" :key="index*30" v-if="item.child.length != 0">
                        <template #title>
                            <i v-if="item.id === 2" style="cursor: pointer;font-size: 16px;margin-right: 10px"
                               class="iconfont">&#xe740;</i>
                            <i v-if="item.id === 1" style="cursor: pointer;font-size: 20px;margin-right: 10px"
                               class="iconfont">&#xe6c8;</i>
                            <span slot="title">{{item.title}}</span>
                        </template>
                        <el-menu-item :index="items.path + ''" v-for="(items, indexs) in item.child" :key="indexs">
                            {{items.title}}
                        </el-menu-item>
                    </el-submenu>
                </template>
            </el-menu>
        </div>
        <div style="flex: 0.01; background-color: #f0f0f7"></div>
        <div class="customerInfoLayout_right">
            <component v-bind:is="currentTabComponent" @save="save"></component>
        </div>
    </div>
</template>

<script>
    import crowdPortrait from './common/crowdPortrait'
    import buyingBehavior from './common/buyingBehavior'
    import flowShop from './common/flowShop'
    import industryData from './common/industryData'
    import tradingData from './common/tradingData'
    import beforeService from './common/beforeService'
    import afterService from './common/afterService'
    import {postCustomerData, getCustomerMenu} from '@/utils/apis.js'
    // 导入防抖函数
    import debounce from './debounce.js'

    export default {
        components: {
            crowdPortrait,
            buyingBehavior,
            flowShop,
            industryData,
            tradingData,
            beforeService,
            afterService,
        },
        name: "customerInfoLayout",
        data() {
            return {
                openeds: ['customer', 'customerService'],
                menuArr: [
                    {
                        id: 1,
                        title: '客户数据',
                        path: 'customer',
                        child: []
                    },
                    {
                        id: 2,
                        title: '客服数据',
                        path: 'customerService',
                        child: []
                    }
                ],
                defaultActive: '',
                customer_data: [
                    {title: '人群画像', path: 'crowdPortrait'},
                    {title: '客户购买行为', path: 'buyingBehavior'},
                    {title: '店铺流量', path: 'flowShop'},
                    {title: '行业数据', path: 'industryData'},
                    {title: '交易数据', path: 'tradingData'},
                ],
                kefu_data: [
                    {title: '售前服务', path: 'beforeService'},
                    {title: '售后服务', path: 'afterService'},
                ],
                currentTabComponent: 'crowdPortrait',
                saveType: '',
                saveVal: {}
            }
        },
        methods: {
            dataAggregationClick() {
                const { href } = this.$router.resolve({
                    path: "/student/dataSummarization/index",
                    query: {

                    }
                });
                let res = localStorage.getItem("currentNav");
                if (!!res) localStorage.removeItem("currentNav");
                window.open(href, '_blank');
            },
            menuItemSelect(key) {
                this.currentTabComponent = key
            },
            // 提交数据
            save(type, val) {
                console.log(type, val)
                this.saveType = type
                this.saveVal = val
                this.debounceFn()
            },
            debounceFn(){
                debounce(() => {
                    this.postCustomerDataFn(this.saveType, this.saveVal)
                }, 500)
            },
            async postCustomerDataFn(type, val) {
                try {
                    let res = await postCustomerData({
                        data_analysis: val.content1,
                        problem_diagnosis: val.content2,
                        type: type,
                    })
                    if (res.code == 200) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                } catch (e) {
                    console.log(e)
                }
            },

            // 获取侧边菜单栏数据
            async getMenu() {
                try {
                    let res = await getCustomerMenu()
                    res.data.customer_data.forEach((item1) => {
                        this.customer_data.filter((item2) => {
                            if(item1 == item2.title) {
                                this.menuArr[0].child.push(item2)
                            }
                        })
                    })
                    res.data.kefu_data.forEach((item1) => {
                        this.kefu_data.filter((item2) => {
                            if(item1 == item2.title) {
                                this.menuArr[1].child.push(item2)
                            }
                        })
                    })
                    this.judgeMenu()
                } catch (e) {
                    console.log(e)
                }
            },
            judgeMenu() {
                if (this.menuArr[0].child.length != 0) {
                    this.defaultActive=this.menuArr[0].child[0].path
                    this.currentTabComponent=this.menuArr[0].child[0].path
                } else {
                    this.defaultActive=this.menuArr[1].child[0].path
                    this.currentTabComponent=this.menuArr[1].child[0].path
                }
            }
        },
        mounted() {
            this.getMenu()
        },
    }
</script>

<style scoped lang="scss">
    .dataBtn {
        display: inline-block;
        padding: 8px 28px;
        background-color: #F23B2F;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        margin: 18px 38px;
        cursor: pointer;
    }

    .customerInfoLayout {
        width: 100%;
        display: flex;
        height: calc(100vh - 50px);

        .customerInfoLayout_left {
            width: 200px;
            min-width: 200px;
            box-shadow: 0px 0px 16px 0px rgba(101, 30, 30, 0.1000);
        }

        .customerInfoLayout_right {
            flex: 0.99;
        }
    }

    .textColor {
        color: #F23B2F;
    }

    .el-menu {
        border: none;
    }

    .submitBtn {
        display: inline-block;
        color: #FFFFFF;
        padding: 10px 28px;
        background-color: #F23B2F;
        border-radius: 4px 4px 4px 4px;
        position: absolute;
        bottom: 90px;
        left: 52px;
        cursor: pointer;
    }
</style>