<template>
    <div class="crowdPortrait">
        <dataAnalysisTemplate :echoData="echoData" :showData="crowdPortrait" @save="save"></dataAnalysisTemplate>
    </div>
</template>

<script>
    import dataAnalysisTemplate from "./dataAnalysisTemplate";
    import { getDataAnalysisInfo } from '../../../../utils/apis'

    export default {
        name: "crowdPortrait",
        components: {
            dataAnalysisTemplate
        },
        data() {
          return {
              crowdPortrait: 'crowdPortrait',
              echoData: {}
          }
        },
        methods: {
            save(val) {
                this.$emit('save', 1,val)
            },
            async getDataAnalysisInfoFn() {
                let res = await getDataAnalysisInfo({
                    type: 1
                })
                this.echoData = res.data
            }
        },
        mounted() {
            this.getDataAnalysisInfoFn()
        }
    }
</script>

<style scoped>
    .crowdPortrait{
        width: 100%;
        height: 100%;
    }
</style>